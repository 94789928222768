.icon-account {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-arrow_right {
  width: 1.41em;
  height: 1em;
  fill: rgb(0, 0, 0);
}
.icon-cart {
  width: 1.33em;
  height: 1em;
  fill: none;
}
.icon-checked {
  width: 1.42em;
  height: 1em;
  fill: rgb(255, 255, 255);
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: none;
}
.icon-fb {
  width: 0.53em;
  height: 1em;
  fill: #F2F2F2;
}
.icon-heart {
  width: 1.09em;
  height: 1em;
  fill: none;
}
.icon-inst {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-logout {
  width: 1.17em;
  height: 1em;
  fill: #667F8E;
}
.icon-minus {
  width: 8em;
  height: 1em;
  fill: none;
}
.icon-plus {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-tw {
  width: 1.21em;
  height: 1em;
  fill: #FFFFFF;
}
.icon-vk {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-vk2 {
  width: 1.69em;
  height: 1em;
  fill: #888888;
}
