.pagination {
	display: flex;
	flex-flow: wrap;

	&__item {
		border: 1px solid #f5f5f5;
		overflow: hidden;

		&:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		&:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		&:not(:first-child) {
			margin-left: -1px;
		}
	}

	.active {
		a {
		z-index: 3;
		color: @pagination-active-color;
		cursor: default;
		background-color: @pagination-active-bg;
		border-color: @pagination-active-border;
		}
	}
}
