//@include font-face('Black', 900);


@font-face {
	font-family: 'RobotoMono';
	src: url('../fonts/RobotoMono-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/RobotoMono-Regular.woff') format('woff'),
	url('../fonts/RobotoMono-Regular.ttf')  format('truetype'),
	url('../fonts/RobotoMono-Regular.svg#RobotoMono-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'RubikMonoOne';
	src: url('../fonts/RubikMonoOne-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/RubikMonoOne-Regular.woff') format('woff'),
	url('../fonts/RubikMonoOne-Regular.ttf')  format('truetype'),
	url('../fonts/RubikMonoOne-Regular.svg#RubikMonoOne-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Rubik';
	src: url('../fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Rubik-Black.woff') format('woff'),
	url('../fonts/Rubik-Black.ttf')  format('truetype'),
	url('../fonts/Rubik-Black.svg#Rubik-Black') format('svg');
	font-weight: normal;
	font-style: normal;
}
