button,
.btn {
	position: relative;
	display: inline-block;
	border-radius: 0;
	border: 0;
	transition: .3s all;
	background-color: transparent;
	text-align: center;
	padding: 0;
	cursor: pointer;
	font-family: inherit;

	&.is-loading {
		pointer-events: none;
	}
}

.btn--primary {
	background-color: #000;
	color: #fff;
	text-transform: uppercase;
	padding: 10px 20px 12px;
	border-radius: 10px;

	&:hover {

	}

	&.btn--lg {
		height: 67px;
		line-height: 67px;
		padding: 0 41px;
	}

	.icon-checked:not(:last-child) {
		margin-right: 20px;
	}
}

.btn--secondary {

	&:hover {

	}
}

.btn--uppercase {
	text-transform: uppercase;
}

.btn--profile {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-transform: uppercase;
	color: #fff;

	&:hover {
		color: $primary-color;

		svg {
			fill: $primary-color;
		}
	}

	.icon-account {
		fill: #fff;
		width: 26px;
		height: 26px;
		margin-right: 14px;
		transition: .3s all;
	}
}


.btn--catalog {
	background-color: #fff;
	border-radius: 10px;
	text-transform: uppercase;
	box-shadow: 0px 2px 12.45px 2.55px rgba(0, 0, 0, 0.1);
	padding: 7px 21px 7px 7px;
	display: inline-flex;
	align-items: center;


	svg {
		margin-right: 10px;
	}
}

.btn--favorite {

	&.btn--lg {

		.btn__icon {
			width: 45px;
			height: 45px;
			border-radius: 100px;
			border: 1px solid $primary-color;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding-top: 2px;

			svg {
				fill: $primary-color;
				width: 23px;
				height: 23px;
			}
		}

		.badge {
			width: 26px;
			height: 20px;
			border: 1px solid $primary-color;
			border-radius: 5px;
			color: $primary-color;
			display: block;
			line-height: 18px;
			position: absolute;
			bottom: -12px;
			right: -9px;
			background-color: #fff;
		}
	}

	&:not(.btn--lg) {

		svg {
			width: 23px;
			height: 23px;
			stroke: #000;
			overflow: visible;

		}
	}

	&.is-active {

		svg {
			fill: $primary-color;
			stroke: none;
		}
	}
}


.btn--cart {
	//width: 204px;
	//height: 84px;

	width: 190px;
	height: 78px;

	background-image: url('../img/btn_cart_bg.svg');
	@include cover();
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 13px 0 22px;
}


.btn--toCart {
	width: 45px;
	height: 45px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: $green;
	border-radius: 100%;

	&.is-loading {

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 100%;
			background-image: url('../img/preloader-main-white.gif');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 30px 30px;
		}

		> * {
			opacity: 0;
		}
	}

	svg {
		fill: #000;
		width: 24px;
		height: 18px;
	}

	svg.icon-checked {
		fill: #fff;
	}
}

.btn--arrowRight {
	text-transform: uppercase;
	color: #fff;
	font-family: $font-secondary;
	display: flex;
	align-items: center;

	.icon-arrow_right {
		width: 31px;
		height: 22px;
		fill: #fff;
		margin-left: auto;
	}

	.icon-inst,
	.icon-vk {
		width: 26px;
		height: 26px;
		fill: #fff;
		margin-right: 16px;

		+ span {
			font-family: $font-main;
		}
	}
}

.btn--link {
	letter-spacing: 0;
	color: #000;
	text-transform: uppercase;

	&.btn--linkGray {
		color: #a2a2a2;

		&:hover {
			color: $primary-color;
		}
	}

	&.btn--xs {
		text-transform: initial;
		font-size: 12px;
		line-height: 18px;
	}
}

.btn--transparent {
	height: 67px;
	line-height: 67px;
	text-transform: uppercase;
	text-align: center;
	border: 1px solid #e1e1e1;
	border-radius: 10px;

	.icon-plus {
		vertical-align: middle;
		width: 15px;
		height: 15px;
		fill: #000;
	}
}

.btn--logout {
	display: flex;
	align-items: center;

	&:hover svg {
		fill: $primary-color;
	}

	svg {
		fill: #fff;
		width: 25px;
		height: 25px;
		vertical-align: middle;
		transition: .3s all;
	}
}
