.cartMini {
	position: fixed;
	top: 55px;
	right: 30px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 2px 12.45px 2.55px rgba(0, 0, 0, 0.1);
	width: 308px;
	z-index: 100;
	height: calc(100vh - 55px - 15px);
	display: none;

	&.is-active {
		display: block;
	}

	&__head {
		padding: 20px 20px 17px;
		border-bottom: 1px solid #e1e1e1;
		position: relative;
	}

	&__title {
		font-family: $font-secondary;
		letter-spacing: 0;

		span {
			font-family: $font-main;
		}
	}

	&__close {
		position: absolute;
		top: 50%;
		margin-top: 5px;
		right: 10px;
		transform: translateY(-50%);

		&:hover svg {
			fill: $primary-color;
		}

		svg {
			width: 33px;
			height: 33px;
			fill: #c9c9c9;
			transition: .3s all;
		}
	}

	&__body {
		height: calc(100% - 67px - 100px);
		position: relative;

		> div {
			height: 100%;
		}

		.cartMiniEmpty {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			.cartMiniEmpty__button:not(:first-child) {
				margin-top: 30px;
			}
		}
	}

	&__list {

		li:not(:first-child) {
			border-top: 1px solid #e1e1e1;
		}
	}

	&__bottom {
		border-top: 1px solid #e1e1e1;
		padding: 5px 20px 10px;

		.cartMini__top {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.cartMini__bottom {
			padding: 0;
			margin: 5px -10px 0;
			border: 0;

			.btn {
				width: 100%;
			}
		}
	}
}

