.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.modal__container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

.modal__inner {

}
