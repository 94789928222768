.suggestions-nowrap {
	white-space: nowrap
}

.suggestions-input {
	box-sizing: border-box;
	width: 100%
}


.suggestions-input::-ms-clear {
	display: none
}

.suggestions-wrapper {
	position: relative;
	margin: 0;
	padding: 0;
	vertical-align: top;
	-webkit-text-size-adjust: 100%;
	letter-spacing: 0;

}

.suggestions-addon {
	display: none;
	opacity: 0;
	position: absolute
}

.suggestions-addon[data-addon-type=spinner] {
	background: transparent url('../img/preloader/preloader-main.gif') no-repeat center center;
	background-size: 30px 30px;
	border-radius: 50%
}

.suggestions-addon[data-addon-type=clear] {
	background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAwJyBoZWlnaHQ9JzEwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48Zz48cGF0aCBkPSdtMjAsMjBtMTAsMGwyMCwyMGwyMCwtMjBsMTAsMTBsLTIwLDIwbDIwLDIwbC0xMCwxMGwtMjAsLTIwbC0yMCwyMGwtMTAsLTEwbDIwLC0yMGwtMjAsLTIwbDEwLC0xMCcgZmlsbD0nIzk5OScvPjwvZz48L3N2Zz4=) 50% 50% no-repeat;
	background-size: contain;
	cursor: pointer;
	opacity: .8
}

.suggestions-addon[data-addon-type=clear]:hover {
	opacity: 1
}

.suggestions-suggestions {
	box-shadow: 0 0 10px -6px;
	background-color: #fff;
	box-sizing: border-box;
	cursor: default;
	z-index: 9999;
	-webkit-text-size-adjust: 100%
}

.suggestions-suggestions strong {
	font-weight: 400;
	color: $primary-color;
}

.suggestions-suggestions.suggestions-mobile {
	border-style: none
}

.suggestions-suggestions.suggestions-mobile .suggestions-suggestion {
	border-bottom: 1px solid #ddd
}

.suggestions-suggestion {
	padding: 10px;
	overflow: hidden;
}

.suggestions-suggestion:hover {
	background: #f7f7f7
}

.suggestions-selected {
	background: #f0f0f0
}

.suggestions-selected:hover {
	background: #f0f0f0
}

.suggestions-hint {
	padding: 4px 10px;
	white-space: nowrap;
	overflow: hidden;
	color: $primary-color;
	font-size: 85%;
	line-height: 20px
}

.suggestions-constraints {
	list-style: none !important;
	margin: 0 !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important
}

.suggestions-constraints:empty {
	display: none !important
}

.suggestions-constraints li {
	background: #f8f8f8;
	border: 1px solid #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	cursor: default;
	display: inline-block;
	margin: 0 4px 0 0;
	padding: 0 .5em
}

.suggestions-constraints li .suggestions-remove {
	height: 1em;
	width: 1em;
	display: inline-block;
	margin: 0 0 0 .25em;
	background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAwJyBoZWlnaHQ9JzEwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48Zz48cGF0aCBkPSdtMjAsMjBtMTAsMGwyMCwyMGwyMCwtMjBsMTAsMTBsLTIwLDIwbDIwLDIwbC0xMCwxMGwtMjAsLTIwbC0yMCwyMGwtMTAsLTEwbDIwLC0yMGwtMjAsLTIwbDEwLC0xMCcgZmlsbD0nIzk5OScvPjwvZz48L3N2Zz4=) 50% 50% no-repeat;
	background-size: contain;
	cursor: pointer;
	opacity: .8
}

.suggestions-constraints li .suggestions-remove:hover {
	opacity: 1
}

.suggestions-constraints li span {
	vertical-align: middle
}

.suggestions-subtext {
	color: #777;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.suggestions-subtext_inline {
	display: inline-block;
	min-width: 6em;
	vertical-align: bottom;
	margin: 0 .5em 0 0
}

.suggestions-subtext-delimiter {
	display: inline-block;
	width: 2px
}

.suggestions-subtext_label {
	margin: 0 0 0 .25em;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 0 3px;
	background: #f5f5f5;
	font-size: 85%
}

.suggestions-value[data-suggestion-status=LIQUIDATED] {
	position: relative
}

.suggestions-value[data-suggestion-status=LIQUIDATED]:after {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	border-top: 1px solid rgba(0, 0, 0, .4);
	content: ""
}

.suggestions-promo {
	font-size: 85%;
	display: none !important;
	color: #777;
	padding: 4px;
	text-align: center
}

.suggestions-promo a {
	color: #777;
	display: block;
	filter: grayscale(100%);
	line-height: 20px;
	text-decoration: none
}

.suggestions-promo a:hover {
	filter: grayscale(0)
}

.suggestions-promo svg {
	height: 20px;
	vertical-align: bottom
}

.suggestions-promo-desktop {
	position: absolute;
	top: 0;
	right: 0;
	text-align: left
}
