.section--search {
	padding-bottom: 90px;

	.grid.grid--4 {
		margin-top: 50px;
	}

	.pagination {
		margin-top: 40px;
	}
}
