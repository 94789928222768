.shareBox {
	display: flex;
	align-items: center;
	justify-content: center;

	&__item {
		margin: 5px;
		background-color: #f5f5f5;
		border-radius: 10px;
		display: flex;
		align-items: center;
		letter-spacing: 0;
		padding: 5px 10px;

		svg {
			fill: #000;
			vertical-align: middle;
			margin-right: 11px;
		}

		.icon-vk2 {
			width: 28px;
			height: 16px;
		}

		.icon-fb {
			width: 11px;
			height: 22px;
		}

		.icon-tw {
			width: 22px;
			height: 18px;
		}
	}
}
