.section--catalog {
	padding: 10px 0 90px;
}

.catalog {
	display: flex;

	.catalog__title {
		margin-bottom: 20px;
	}

	.catalog__fullWidth {
		width: 100%;
	}

	.catalog__left {
		width: $column-sm;
		padding-top: 18px;
	}

	.catalog__right {
		width: $column-lg2;
		margin-left: auto;

		.grid {
			margin-top: 32px;
			margin-bottom: 30px;
		}
	}
}


.catalogNav {
	display: flex;
	flex-flow: wrap;
	margin: -5px;

	&__item {
		padding: 5px;
	}
}

.catalogNavCategory {
	background-color: #f5f5f5;
	border-radius: 10px;
	display: flex;
	align-items: center;
	height: 83px;
	padding: 0 20px;

	&.is-active {
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 2px 12.45px 2.55px rgba(0, 0, 0, 0.1);
		pointer-events: none;
	}

	&__image {
		position: relative;
		margin-right: 8px;
		width: 83px;
		height: 83px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__title {
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&__count {
		margin-left: 11px;
	}
}
