.card--article {
	letter-spacing: 0;

	&:hover:before {
		opacity: 1;
	}

	&:before {
		content: '';
		position: absolute;
		top: -10px;
		right: -10px;
		bottom: -15px;
		left: -10px;
		border-radius: 10px;
		background-color: #fff;
		box-shadow: 0px 2px 12.45px 2.55px rgba(0, 0, 0, 0.1);
		opacity: 0;
		transition: .3s all;
	}

	> div {
		position: relative;
	}

	&.card--lg {
		color: #fff;

		.card__photo {
			height: 402px;
			margin-bottom: 0;
		}

		.card__body {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			padding: 19px 30px;
			width: 65%;
		}

		.card__date {
			margin-bottom: 10px;
		}

		.card__title {
			font-size: 36px;
			line-height: 47px;
		}

		.card__description {
			position: absolute;
			left: 100%;
			bottom: 35px;
			width: 50%;
			margin-left: 30px;
		}
	}

	.card__photo {
		height: 268px;
		@include cover();
		position: relative;
		margin-bottom: 10px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #000;
			opacity: .25;
		}
	}

	.card__date {
		color: #a2a2a2;
		margin-bottom: 6px;
	}

	.card__title {
		font-weight: 700;
		font-size: 21px;
		line-height: 27px;
		margin-bottom: 9px;
	}

	.card__description {
		font-size: 14px;
		line-height: 21px;
	}

}
