.out {
	position: relative;
	overflow: hidden;
}

.pageWrapper {
	min-height: calc(100vh - 160px - 187px);
}

.no-scroll {
	overflow: hidden;
}

.is-fixed {
	position: fixed;
	top: 0;
	left: 0;
}

.swiper-button {
	border-radius: 50%;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 2px 8.3px 1.7px rgba(0, 0, 0, 0.16);
	width: 60px;
	height: 60px;
	background-size: 31px 22px;

	&.swiper-button-next {
		background-image: url('../img/arrow_right.svg');
	}

	&.swiper-button-prev {
		background-image: url('../img/arrow_left.svg');
	}
}

.section__title {
	text-align: center;
	font-size: 45px;
	font-weight: 700;
}

.page__title {
	font-size: 30px;
	line-height: 40px;
	text-transform: uppercase;
	font-family: $font-secondary;
	text-align: center;
	letter-spacing: 0;
}

.page__subtitle {
	font-family: $font-secondary;
	text-align: center;
	letter-spacing: 0;
	margin-top: 10px;
}

.section--paddingTopDefault {
	padding-top: 30px;
}
