.cartBox {

	&__row {
		min-height: 61px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:not(:first-child) {
			margin-top: 4px;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #e1e1e1;
		}
	}

	&__title {
		text-transform: uppercase;
	}

	&__total {
		width: 100%;
		text-align: center;

		.price {
			font-size: 30px;
		}
	}
}

.input--codeSale {
	text-align: right;
}
