.title--lg {

}

.title--md {

}

.title--sm {

}

.color--black {
	color: $black;
}

.text--center {
	text-align: center;
}
