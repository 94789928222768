.radioButtonPrice {
	position: relative;
	display: inline-block;

	[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
		opacity: 0;

		&:checked + div {
			background-color: $primary-color;
			color: #fff;
		}
	}

	&__body {
		border: 1px solid $primary-color;
		border-radius: 5px;
		line-height: 1;
		padding: 5px 12px 4px;
		color: $primary-color;
	}

	.price {
		font-family: $font-third;
	}
}
