.section--cart {
	padding-bottom: 90px;

	.cart {
		margin-top: 63px;
	}
}

.goodsList {

	.goodsList__item:not(:first-child) {
		margin-top: 5px;
	}
}

.cartBottom {
	text-align: center;
	margin-top: 90px;
}

.cart {
	display: flex;
	justify-content: flex-end;

	&.is-locked {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(#fff, .8);
			background-image: url('../img/preloader-main.gif');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 50px 50px;
			z-index: 100;
		}
	}

	.cart__center {
		width: percentage(760/1369);
	}

	.cart__right {
		width: percentage(278/1369);
		margin-left: 35px;
	}
}

.card--cart {
	display: flex;

	.card__photo {
		width: 61px;
		height: 61px;
		@include cover();
	}

	.card__body {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e1e1e1;
		margin-left: 15px;
		width: calc(100% - 15px - 61px);

		.card__title {
			width: percentage(441/699);
		}

		.card__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: percentage(258/699);
			position: relative;

			.card__price {
				width: 50%;
				margin-left: 55px;
			}
		}
	}

	.btn--remove {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);

		&:hover svg {
			opacity: 1;
		}

		svg {
			width: 12px;
			height: 13px;
			fill: #000;
			opacity: .5;
			transition: .3s all;
		}
	}

	.quantity {

		&__value {
			width: 75px;
		}
	}
}


.orderForm {

	.form__inner {
		background-color: #f5f5f5;
		width: 100%;
		border-radius: 20px;
		padding: 21px 17px;

		> div:not(:first-child) {
			margin-top: 22px;
		}
	}

	.form__title {
		margin-bottom: 5px;
	}

	.form__bottom {
		margin-top: 20px;
	}



	.form .form__button {
		margin-top: 40px;

		.btn {
			width: auto;
			min-width: 278px;
		}
	}

}

.form__grid--3 {
	display: flex;
	flex-flow: wrap;
	margin: -6px;

	> div {
		width: 33.33%;
		padding: 6px;
	}
}

.form__grid--2 {
	display: flex;
	flex-flow: wrap;
	margin: -6px;

	> div {
		width: 50%;
		padding: 6px;
	}
}
