.goodCard {
	display: flex;
	flex-flow: wrap;
	margin: 0 -17.5px;
	padding-bottom: 50px;
	position: relative;

	> div {
		padding: 0 17.5px;

		&.goodCard__left {
			width: percentage(832/1398);
			height: 400px;

			.goodCardSlider, .goodCardSlider .swiper-container {
				height: 100%;

				img {
					max-height: 100%;
				}
			}
		}

		&.goodCard__right {
			width: percentage(531/1398);
			text-align: center;
			margin-left: auto;
		}

		&.goodCard__bottom {
			width: 100%;
			margin-top: 82px;
		}
	}

	.breadcrumbs {
		margin-top: -12px;
	}

	&__title {
		font-size: 30px;
		line-height: 45px;
		font-family: $font-secondary;
		margin-top: 8px;
	}

	&__status {
		font-size: 14px;
		color: #a2a2a2;
		text-transform: uppercase;
		margin-top: 7px;
	}

	&__order {
		margin-top: 61px;

		.goodCard__quantity {

		}
	}

	&__price {
		margin-top: 28px;

		.price {
			font-size: 30px;
		}
	}

	&__buttons {
		margin-top: 22px;

		.btn--primary {
			min-width: 234px;
		}
	}
}

.goodCardSlider {
	text-align: center;
	position: relative;
	background-color: #f5f5f5;

	.swiper-button {
		top: auto;
		margin-top: 0;
		bottom: 10px;
	}

	.swiper-slide {
		width: auto;

		&:first-child:last-child {
			width: 100%;
		}
	}
}

.goodCardDescription {
	//max-width: 576px;
	margin: 0 auto;

	p {
		font-size: 14px;
		line-height: 28px;
		text-transform: uppercase;
	}

	table {
		width: 100%;
		text-transform: uppercase;

		td:last-child {
			text-align: right;
		}
	}
}
