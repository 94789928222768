.radioButton {
	position: relative;

	> [type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		z-index: 2;
		opacity: 0;

		&:checked {
			pointer-events: none;
		}

		&:checked + .radioButton__body {
			border-color: #fff;
			background-color: #fff;
			box-shadow: 0px 2px 12.45px 2.55px rgba(0, 0, 0, 0.1);

			&:before {
				background-color: $primary-color;
			}
		}
	}

	.radioButton__body {
		border: 1px solid #e1e1e1;
		border-radius: 10px;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		padding: 2px 20px 0px 10px;
		height: 100%;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 11px;
			right: 6px;
			width: 12px;
			height: 12px;
			border-radius: 100px;
			background-color: #e1e1e1;
		}

		> span {
			text-transform: uppercase;

			&:first-child {

				&:last-child {
					min-height: 92px;
					display: flex;
					align-items: center;
				}
			}
		}

		.textBottom {
			margin-top: 3px;
		}
	}
}
