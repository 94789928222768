.hideFields {

	&.is-active {

		.hideFields__button {
			display: none;
		}

		.hideFields__body {
			display: block;
		}
	}

	&__button {

		.btn {
			width: 100%;
		}
	}

	&__body {
		display: none;
	}
}
