.nav--catalog {
	text-transform: uppercase;

	.menu {

		&:not(:first-child) {
			margin-top: 42px;
		}

		.menu__item:not(:first-child) {
			margin-top: 7px;
		}

		.menu__item.is-active {

			> a {
				color: #a2a2a2;
				position: relative;
				pointer-events: none;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: -15px;
					transform: translateY(-50%);
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background-color: #e1e1e1;
				}
			}

			> .subMenu {
				display: block;
			}
		}
	}

	.subMenu {
		margin-top: 8px;
		margin-bottom: 43px;
		padding-left: 20px;
		display: none;

		&__item {
			> a {
				text-transform: initial;
				letter-spacing: 0;
			}
		}

		&__item:not(:first-child) {
			margin-top: 6px;
		}
	}
}

.nav--primary {
	text-transform: uppercase;

	.menu {
		display: flex;
		flex-flow: wrap;

		.menu__item {
			width: 50%;

			&:not(:nth-child(-n+2)) {
				margin-top: 7px;
			}
		}
	}
}

.nav--page {
	.menu {

		&:not(:first-child) {
			margin-top: 42px;
		}

		.menu__item:not(:first-child) {
			margin-top: 7px;
		}

		.menu__item.is-active {

			> a {
				color: #a2a2a2;
				position: relative;
				pointer-events: none;

				&:before {
					content: '';
					position: absolute;
					top: 8px;
					left: -15px;
					width: 6px;
					height: 6px;
					border-radius: 100%;
					background-color: #e1e1e1;
				}
			}
		}

		a {
			text-transform: uppercase;
		}
	}
}
