.card--historyOrder {
	background-color: #f5f5f5;
	border-radius: 20px;
	padding: 18px 20px 5px;
	min-height: 61px;


	.card__row,
	.card__head {
		display: flex;
		align-items: center;

		.card__left {
			display: flex;
			flex-flow: wrap;
			align-items: center;
			width: percentage(480/716);
		}

		.card__right {
			width: percentage(196/716);
		}
	}

	.card__body {
		margin: 18px 0;

		.card__right {
			display: flex;
			flex-flow: wrap;

			.card__price,
			.card__value {
				width: 50%;
				text-transform: uppercase;
			}

			.card__price {
				margin-left: auto;
			}
		}
	}

	.card__list {
		.card__row {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 76px;
				right: 0;
				height: 1px;
				background-color: #e1e1e1;
			}

			&:before {
				top: -3px;
			}

			&:after {
				bottom: -3px;
			}

			&:not(:first-child) {
				margin-top: 5px;
			}

			&:last-child {

				&:after {
					content: '';
					position: absolute;
					left: 76px;
					right: 0;
					height: 1px;
					background-color: #e1e1e1;
				}
			}
		}
	}

	.cardInfo {
		line-height: 21px;
		letter-spacing: 0;
	}

	.cardTotal {
		margin-top: 13px;

		.card__row:not(:first-child) {
			border-top: 1px solid #e1e1e1;
			margin-top: 13px;
			padding-top: 16px;
		}
	}

	.card__photo {
		width: 61px;
		height: 61px;
		@include cover();
		margin-right: 15px;
	}

	.card__title {
		letter-spacing: 0;
	}

	.card__status {
		font-family: $font-secondary;
	}

	.card__code {
		font-family: $font-secondary;
		margin-right: 15px;
		letter-spacing: 0;
	}

	.card__date {
		text-transform: uppercase;
		letter-spacing: 0.06em;
	}
}
