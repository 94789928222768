.section--error {
	padding-top: 20px;
	padding-bottom: 100px;
}

.errorPage {
	&__head {
		text-align: center;
		padding: 47px 0;

		@include r($xs-breakpoint) {
			padding: 20px 0 0;
		}

		.logo {
			display: inline-block;
		}
	}

	&__body {
		text-align: center;
	}

	&__title {
		margin-top: 117px;
		//font-size: 42px;
		//font-weight: 700;

		@include r($xs-breakpoint) {
			//font-size: 22px;
			//margin-top: 40px;
		}
	}

	&__subtitle {
		font-size: 14px;
		line-height: 25px;
		color: #000;
		margin: 37px auto 0;
		max-width: 510px;

		@include r($xs-breakpoint) {
			font-size: 13px;
			margin-top: 15px;
		}

		a {
			color: $primary-color;

			&:hover {
				color: #000;
			}
		}
	}

	&__bottom {
		margin-top: 42px;

		@include r($xs-breakpoint) {
			margin-top: 30px;
		}
	}
}

.errorIcon404 {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 84px;

	@include r($xs-breakpoint) {
		margin-top: 40px;
	}

	span {
		font-size: 220px;
		color: #f3f3eb;
		font-weight: 700;

		@include r($sm-breakpoint) {
			font-size: 150px;
		}

		@include r($xs-breakpoint) {
			font-size: 90px;
		}
	}

	img {
		display: inline-block;
		margin: 0 30px;
		position: relative;
		left: -20px;

		@include r($sm-breakpoint) {
			width: 265px;
			height: 265px;
		}

		@include r($xs-breakpoint) {
			width: 120px;
			height: 120px;
			left: -10px;
			margin: 0 20px;
		}
	}
}

.errorIconDefault {
	font-size: 220px;
	color: $primary-color;
	font-weight: 700;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 84px;

	@include r($sm-breakpoint) {
		font-size: 150px;
	}

	@include r($xs-breakpoint) {
		font-size: 90px;
		margin-top: 40px;
	}
}
